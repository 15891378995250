import PageLayout from '@/components/PageLayout';
import SEOlinksA from '@/components/SEOlinksA';
import Button from '@/components/UI/Button';
import Image from '@/components/UI/Image';
import Input from '@/components/UI/Input';
import { message } from '@/components/UI/Message';
import { EFAkIn, EFAkout, EFLoginIcon1, EFLoginIcon2, EFLoginIcon3 } from '@/components/UI/svg/My';
import { ENGLISH_LANG, i18nMap, newVersionRegion, rpcMap } from '@/constants';
import seoconfig from '@/seoconfig/login';
import { authSignIn, checkHumanMachine, toLogin } from '@/service';
import { getHostApi, redirectTo404 } from '@/utils';
import { onLogin } from '@/utils/cookie';
import { getCountryRegion } from '@/utils/countryRegion';
import { getKaamelPlan } from '@/utils/kaamelTest';
import { useGlobal } from '@/utils/useGlobal';
import { Form } from 'antd';
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
import Head from 'next/head';
import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import css from './login.module.scss';
const wordpress_site = process.env.NEXT_PUBLIC_WORDPRESS_SITE;
const HOMEBATTERY_REDIRECT = process.env.NEXT_PUBLIC_HOME_BATTERY_DOMAIN;

// previous routes which aren't allow
const prevUrlBalckList = ['/login', '/register', '/validate', '/forget'];

export default function Login({ redirect: redirectUrl, prevPath, lang, SEO }: any) {
    if (redirectUrl.split('redirect=')[1]) {
        redirectUrl = redirectUrl.split('redirect=')[1];
    }
    const WEB_HOST = process.env.NEXT_PUBLIC_WEB_HOST;
    const { useRouter, Link, useLang, gtm } = useGlobal();
    const { locale, query } = useRouter();
    const { t } = useLang('shared');
    const errTipRef = useRef(null);
    const isNewVersionRegion = newVersionRegion(locale);
    const recaptchaRef = React.useRef();
    const SECRET_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_REGISTER_SECRET_KEY;

    const router = useRouter();
    const [loading, setLoading] = useState(false);
    const [errorTip, setErrorTip] = useState('');
    const [isErrorTip, setIsErrorTip] = useState(false);
    const [focus, setFocus] = useState('');
    const [allValidate, setAllValidate] = useState({ email: false, password: false });
    const [humanCheck, setHumanCheck] = useState(false);
    const [isShowForget, setIsShowForget] = useState(true);
    const [recaptchaResponse, setRecaptchaResponse] = useState('');
    const [email, setEmail] = useState('');
    const [pwd, setPwd] = useState('');
    const [kaamelInfo, setKaamelInfo] = useState<any>(null);
    const hl = rpcMap[ENGLISH_LANG.includes(locale) ? 'en' : locale];
    let HOME_HOST = `${WEB_HOST}/${router.locale}`;
    // 家储
    if (redirectUrl.indexOf('https://homebattery') > -1 || redirectUrl.indexOf('https://enterprise') > -1) {
        HOME_HOST = `${HOMEBATTERY_REDIRECT}${router.locale}`;
    }
    const [form] = Form.useForm();
    const LOGIN_HOST = getHostApi(router.locale).NEXT_PUBLIC_LOGIN_HOST;
    let open_site: string | undefined = '';
    switch (getCountryRegion(locale)) {
        case 'cn':
            open_site = process.env.NEXT_PUBLIC_OPEN_SITE_CN;
            break;
        case 'eu':
            open_site = process.env.NEXT_PUBLIC_OPEN_SITE_EU;
            break;
        default:
            open_site = process.env.NEXT_PUBLIC_OPEN_SITE;
            break;
    }

    /**
     * @description: 判断链接是内部链接还是外部，然后统一转换成完整url
     * @params url {string}
     * @return url {string}
     */
    const linkHandle = (url: string) => {
        const regex = /^(http|https|ftp):\/\//;
        if (!regex.test(url)) {
            return `${WEB_HOST}${url}`;
        }
        return url;
    };

    const getRegisterUrl = () => {
        let url = '/register';
        const { inviteCode } = query;
        // 之前的逻辑
        // const concatRedirectUrl = redirectUrl.indexOf('/support/warranty') > -1;
        // url += `?redirect=${encodeURIComponent(redirectUrl || `/${locale}/login`)}`;
        if (redirectUrl) {
            url += `?redirect=${encodeURIComponent(redirectUrl)}`;
        }
        // 新增裂变邀请码
        if (inviteCode) {
            if (!url.includes(inviteCode)) {
                url += `&inviteCode=${inviteCode}`;
            }
        }

        gtm.push({
            event: 'uaEvent',
            event_name: 'login_register_switch',
            button_name: lang.site_login_register,
        });

        window.location.href = `/${router.locale || ''}/${url}`;
    };

    // 获取shopify地址
    const getShopifyUrl = (locale: string) => `https://${locale || 'us'}.ecoflow.com`;

    // 获取第三方登录链接
    const getThirdLink = (type: string) => {
        // 是否是家储
        // const IS_HOME_BATTERY = redirectUrl.indexOf('https://homebattery') > -1 || redirectUrl.indexOf('https://enterprise') > -1;
        // const rtUrl = IS_HOME_BATTERY ? `${HOME_HOST}/login?redirect=${encodeURIComponent(redirectUrl)}` : HOME_HOST;
        let url = `${LOGIN_HOST}/auth/oauth/webLogin/${type}?returnTo=${HOME_HOST}&shopifyDomain=${getShopifyUrl(
            router.locale,
        )}&returnToLogout=${`${HOME_HOST}/my-account-manage`}&returnToNew=${linkHandle(redirectUrl || HOME_HOST)}`;
        const { inviteCode } = query;
        if (inviteCode) {
            if (!url.includes(inviteCode)) {
                url += `&inviteCode2=${inviteCode}`;
            }
        }
        return url;
    };

    // JP Link Third Login
    const getLinkAuth = (type: string) => {
        let url = `${LOGIN_HOST}/auth/oauth/login/${type}?returnTo=${HOME_HOST}&&returnToNew=${linkHandle(redirectUrl || HOME_HOST)}`;
        const { inviteCode } = query;
        if (inviteCode) url += `&inviteCode2=${inviteCode}`;
        return url;
    };

    // 获取returnToNew值
    const getShopfiyReturnToNew = (url: string) => {
        // redirect > prevPath > home
        const homePath = `/${router?.locale || ''}`;
        if (typeof window === 'undefined') return homePath;

        let { redirect } = router.query;

        const origin = window.location.origin;
        if (redirect && !prevUrlBalckList.some((item: any) => redirect.includes(item))) {
            if (!redirect.startsWith('http')) redirect = `${origin}${redirect.startsWith('/') ? redirect : `/${redirect}`}`;
            return decodeURIComponent(`${redirect}`);
        }

        let prevRoute = validPrePath(prevPath);
        if (prevRoute && !prevUrlBalckList.some((item: any) => prevRoute.includes(item))) {
            if (!prevRoute.startsWith('http')) prevRoute = `${origin}${prevRoute.startsWith('/') ? prevRoute : `/${prevRoute}`}`;
            return decodeURIComponent(`${prevRoute}`);
        }
        return url || decodeURIComponent(`${origin}${homePath}`);
    };

    // 同步登陆shopify
    const syncLoginShopify = async (url: string) => {
        return new Promise(async (resolve, reject) => {
            const returnToNew = getShopfiyReturnToNew(url);
            const params = {
                returnToNew,
                url: `https://${router?.locale}.ecoflow.com`,
            };
            const res = await authSignIn(params, router?.locale);
            if (res?.code !== '0') {
                reject(res?.message);
            } else {
                resolve(res?.data || `/${router.locale}`);
            }
        });
    };

    const handleBeforeFinish = async () => {
        if (humanCheck && recaptchaResponse) {
            onFinish({});
            return;
        }
        const params = {
            functionName: 'login',
            account: email,
        };
        setLoading(true);

        try {
            const res = await checkHumanMachine(params);
            setLoading(false);

            if (res.code === '0') {
                setHumanCheck(res.data);
                if (!res.data) {
                    onFinish({});
                }
            } else {
                setErrorTip(res.message);
                setIsErrorTip(true);
            }

            return;
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    // Login
    const onFinish = async (values: any) => {
        const recaptchaRes = humanCheck ? { recaptchaResponse } : {};

        const params = {
            source: 'ECOFLOW_WEB',
            userType: 'ECOFLOW', //用户类型
            scene: 'ECOFLOW_WEB', //登陆场景
            logInSource: 1, // 处理注销用户登录
            email: values.email || email,
            password: Base64.stringify(Utf8.parse(values.password || pwd)),
            ...recaptchaRes,
        };

        gtm.push({
            event: 'uaEvent',
            event_name: 'click_login',
            button_name: lang.site_login_login,
        });

        setLoading(true);

        try {
            const res: any = await toLogin(params);

            console.log({ res });
            if (res.code === '0') {
                const redirectUrl = '';
                const { redirect = '', scene } = router.query;
                // 是否是家储
                const IS_HOME_BATTERY = redirect.indexOf('https://homebattery') > -1 || redirect.indexOf('https://enterprise') > -1;
                // 用户信息存储到cookie中
                onLogin(res.data, IS_HOME_BATTERY);

                // 注销中的用户跳转到个人用户页取消注销
                if (res.data?.user?.destroyed === 'DESTROY') {
                    window.location.href = `/${router.locale || ''}/my-account-manage`;
                    return;
                }

                const decodeRedirect = decodeURIComponent(redirect);
                gtm.push({
                    event: 'uaEvent',
                    event_name: 'login',
                    page_name: '/login',
                });

                // wordpress 系统第三方
                if (redirect && scene) {
                    if (redirect.includes(HOMEBATTERY_REDIRECT)) {
                        // window.location.href = `${decodeRedirect}?user=${encodeURIComponent(res.data)}`;
                        window.location.href = decodeRedirect;
                        return;
                    }

                    // 测试环境地址http://10.10.12.33/redirect.html
                    window.location.href = `${wordpress_site}?returnTo=${decodeRedirect}&token=${res.data.token}`;
                    return;
                }

                //open开发平台
                if (window.opener) {
                    window.opener.postMessage(
                        {
                            userInfo: res.data?.user,
                            token: res.data.token,
                        },
                        open_site,
                    );
                }

                /**
                 * @todo 原积分系统已弃用，现在已经迁移到新官网内部
                 */
                // http://10.10.10.168:3007/points?type=website&website=JAPAN&token=xxx
                // 处理个人积分跳转后，登录过期，跳转回来登录，登录成功后再返回到具体url，暂时用http://10.10.10.168:3007站点
                try {
                    const res2 = await syncLoginShopify(redirectUrl);
                    window.location.href = String(res2);
                } catch (err) {
                    window.location.href = `/${router.locale || ''}`;
                } finally {
                    setLoading(false);
                }
            } else {
                gtm.push({
                    event: 'uaEvent',
                    event_name: 'login_fail',
                    page_name: '/login',
                });
                setLoading(false);
                setErrorTip(res.message);
                setIsErrorTip(true);
            }
        } catch (e) {
            gtm.push({
                event: 'uaEvent',
                event_name: 'login_fail',
                page_name: '/login',
            });
            setLoading(false); // 关闭loading
            message.error('error happened');
        }
    };
    // 人机校验 start
    const onReCAPTCHAChange = async (captchaCode: string) => {
        if (!captchaCode) return;
        setRecaptchaResponse(captchaCode);
        // form.validateFields(['recaptchaResponse']);
        form.setFields([
            {
                name: 'recaptchaResponse',
                value: captchaCode,
                errors: [],
            },
        ]);
    };

    // 处理多行错误提示
    useEffect(() => {
        const element: any = errTipRef.current;

        if (!element) return;

        const checkMultiline = () => {
            if (element) {
                const singleLineHeight = parseInt(getComputedStyle(element).lineHeight, 10);
                const totalHeight = element.clientHeight;
                setIsShowForget(!(totalHeight > singleLineHeight));
            }
        };

        const resizeObserver = new ResizeObserver(checkMultiline);
        resizeObserver.observe(element);

        return () => {
            if (element) resizeObserver.unobserve(element);
        };
    }, [errTipRef]);

    useEffect(() => {
        const info = getKaamelPlan();
        setKaamelInfo(info);
    }, []);

    return (
        <PageLayout style={{ background: '#f8f8f8' }}>
            <SEO title={lang.seo_title || lang.title} description={lang.seo_desc || ''} />
            <SEOlinksA locale={locale} list={seoconfig.include} pathname={router.asPath.substr(1)} />
            {locale === 'sa' && (
                <Head>
                    <meta name="robots" content="noindex, nofollow" key="robots" />
                    <meta name="googlebot" content="noindex, nofollow" key="googlebot" />
                </Head>
            )}

            <div className={`${css.login} login_page_a`}>
                <div className={'login-box'}>
                    <div className="left">
                        <Image
                            src="https://ecoflow-service-us-cdn.ecoflow.com/upload/image/1715568541033/login_banner.jpeg"
                            width={400}
                            height={679}
                            alt={lang.site_login_your_ecoflow}
                        ></Image>
                    </div>
                    <div className="right">
                        <h1>{lang.site_login_your_ecoflow}</h1>
                        <div className="content-wrapper">
                            <Form
                                name="normal_login"
                                className="login-form"
                                form={form}
                                autoComplete="off"
                                initialValues={{ remember: true }}
                                onValuesChange={(cValues) => form.validateFields(Object.keys(cValues))}
                            >
                                <div className="inputItem">
                                    <label className={(form.getFieldsValue()['email'] || focus === 'email') && 'active'}>{lang.site_login_email}</label>
                                    <Form.Item
                                        name="email"
                                        shouldUpdate
                                        rules={[
                                            () => ({
                                                validator(_, value) {
                                                    const regx =
                                                        /^[A-Za-z\d]+([.%~`$^*&(){}<>?"+=!_\\-]*[A-Za-z\d-])*@[a-zA-Z\d-]{1,30}\.[a-zA-Z\d.%#~`$^*&(){}<>?"+=!_\\-]{1,30}$/;
                                                    if (!value) {
                                                        return Promise.reject(new Error(lang.site_login_tip_email_empty));
                                                    }

                                                    if (value && !regx.test(value)) {
                                                        setAllValidate({
                                                            ...allValidate,
                                                            ...{ email: false },
                                                        });
                                                        return Promise.reject(new Error(lang.site_login_tip_email_format_incorrect));
                                                    }

                                                    setEmail(value);
                                                    setAllValidate({
                                                        ...allValidate,
                                                        ...{ email: true },
                                                    });
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                        validateTrigger="onBlur"
                                    >
                                        <Input
                                            className="default-input"
                                            onFocus={() => setFocus('email')}
                                            onBlur={() => {
                                                // 邮箱去空格
                                                const { email, password } = form.getFieldsValue();
                                                form.setFieldsValue({ password, email: email?.trim() });
                                                form.validateFields(['email']);
                                                setFocus('');
                                            }}
                                            autoComplete="off"
                                            aria-label={t('logintips.email')}
                                        />
                                    </Form.Item>
                                </div>
                                <input type="password" style={{ display: 'none' }} />
                                <div className="inputItem password-item">
                                    <label className={(form.getFieldsValue()['password'] || focus === 'password') && 'active'}>
                                        {lang.site_login_password}
                                    </label>
                                    <Form.Item
                                        name="password"
                                        shouldUpdate
                                        rules={[
                                            () => ({
                                                validator(_, value) {
                                                    setIsErrorTip(false);
                                                    if (!value) {
                                                        setAllValidate({
                                                            ...allValidate,
                                                            ...{ password: false },
                                                        });
                                                        return Promise.reject(new Error(lang.site_login_tip_password_empty));
                                                    }
                                                    setPwd(value);
                                                    setAllValidate({
                                                        ...allValidate,
                                                        ...{ password: true },
                                                    });
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                        validateTrigger="onChange"
                                    >
                                        <Input.Password
                                            type="password"
                                            className="default-input"
                                            autoComplete="new-password"
                                            onFocus={() => setFocus('password')}
                                            onBlur={() => setFocus('')}
                                            iconRender={(visible: boolean) => <div>{visible ? <EFAkIn className={'iconfont'} /> : <EFAkout />}</div>}
                                            aria-label={t('logintips.pwd')}
                                        />
                                    </Form.Item>
                                    {isErrorTip && (
                                        <div ref={errTipRef} className="error-tip">
                                            {errorTip}
                                        </div>
                                    )}
                                </div>
                                <div className={`${isErrorTip ? 'inline-item validate-error' : 'inline-item'} ${!isShowForget ? 'hide-text' : ''}`}>
                                    <Link
                                        href="/forget"
                                        dataLayer={{
                                            event: 'uaEvent',
                                            event_name: 'forgot_password',
                                            button_name: lang.site_login_forgot_password,
                                        }}
                                        selfWin
                                        ariaLabel={lang.site_login_forgot_password}
                                    >
                                        <span className="login-form-inline-forgot">{lang.site_login_forgot_password}</span>
                                    </Link>
                                </div>

                                {isNewVersionRegion && humanCheck && (
                                    <Form.Item
                                        name="recaptchaResponse"
                                        rules={[
                                            {
                                                validator() {
                                                    if (recaptchaResponse) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(t('logintips.site_login_tip_perform_verification'));
                                                },
                                            },
                                        ]}
                                        style={{ marginTop: '45px', marginBottom: '32px' }}
                                    >
                                        <div className="g-recaptcha">
                                            {/* @ts-ignore */}
                                            <ReCAPTCHA ref={recaptchaRef} hl={hl} sitekey={SECRET_KEY} onChange={onReCAPTCHAChange} />
                                        </div>
                                    </Form.Item>
                                )}
                                <Form.Item className="form-item1">
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="black-btn large login-form-button login-btn"
                                        loading={loading}
                                        disabled={!(allValidate.email && allValidate.password)}
                                        aria-label={lang.site_login_login}
                                        onClick={async () => {
                                            // 先判断是否进行人机校验
                                            if (isNewVersionRegion) {
                                                handleBeforeFinish();
                                            } else {
                                                await onFinish({});
                                            }
                                        }}
                                    >
                                        {lang.site_login_login}
                                    </Button>
                                </Form.Item>
                                {/* <Form.Item className="form-item-share">
                                <Link
                                    ariaLabel={lang.site_login_facebook}
                                    href={`${getThirdLink('facebook')}`}
                                    dataLayer={{
                                        event: 'uaEvent',
                                        event_name: 'click_login',
                                        button_name: 'Facebook',
                                    }}
                                    selfWin
                                >
                                    <Button type="primary" className="large login-form-button fb-btn" aria-label={lang.site_login_facebook}>
                                        <span
                                            style={{
                                                display: 'inline-flex',
                                                verticalAlign: 'middle',
                                                marginRight: '8px',
                                            }}
                                        >
                                            <Image src="/icons/b2f2c7c046760d74e916b7b597497f8b.svg" width="22" height="22" alt={lang.site_login_facebook}></Image>
                                        </span>
                                        {lang.site_login_facebook}
                                    </Button>
                                </Link>
                            </Form.Item> */}
                            </Form>
                            <div className="ecocredit-desc">
                                <div className="ecocredit-desc-title" dangerouslySetInnerHTML={{ __html: lang.ecocredit_title }}></div>
                                <div className="ecocredit-content">
                                    <div className="ecocredit-content-item">
                                        <div className="ecocredit-content-item-icon">
                                            <EFLoginIcon1 />
                                        </div>
                                        <div className="ecocredit-content-item-text">{lang.ecocredit_word1}</div>
                                    </div>
                                    <div className="ecocredit-content-item">
                                        <div className="ecocredit-content-item-icon">
                                            <EFLoginIcon2 />
                                        </div>
                                        <div className="ecocredit-content-item-text">{lang.ecocredit_word2}</div>
                                    </div>
                                    <div className="ecocredit-content-item">
                                        <div className="ecocredit-content-item-icon">
                                            <EFLoginIcon3 />
                                        </div>
                                        <div className="ecocredit-content-item-text">{lang.ecocredit_word3}</div>
                                    </div>
                                </div>
                            </div>
                            <button
                                className="login-form-register-button"
                                onClick={() => {
                                    getRegisterUrl();
                                }}
                            >
                                {lang.site_login_register}
                            </button>
                            <div className="divider-line">{`- ${lang.site_login_or} -`}</div>
                            <div className="items-oterplatform-login">
                                <div className={`google-link ${locale !== 'jp' ? 'full_width' : ''}`}>
                                    <Link
                                        ariaLabel={lang.site_login_google}
                                        href={`${getThirdLink('google4website')}`}
                                        dataLayer={{
                                            event: 'uaEvent',
                                            event_name: 'click_login',
                                            button_name: 'Google',
                                        }}
                                        selfWin
                                    >
                                        <Button className="oterplatform-button" aria-label={lang.site_login_google}>
                                            <span
                                                style={{
                                                    display: 'inline-flex',
                                                    verticalAlign: 'middle',
                                                    marginRight: '12px',
                                                }}
                                            >
                                                <Image src="/icons/0786b44fabc30a5baaa8432d56780609.svg" width="31" height="31" alt={lang.site_login_google}></Image>
                                            </span>
                                            {lang.site_login_google}
                                        </Button>
                                    </Link>
                                </div>
                                {locale === 'jp' && (
                                    <div className="line-link">
                                        <Link
                                            ariaLabel={lang.site_login_google}
                                            href={`${getLinkAuth('line4register')}`}
                                            dataLayer={{
                                                event: 'uaEvent',
                                                event_name: 'click_login',
                                                button_name: 'LINE',
                                            }}
                                            selfWin
                                        >
                                            <Button className="oterplatform-button" aria-label={'Line Button'}>
                                                <span
                                                    style={{
                                                        display: 'inline-flex',
                                                        verticalAlign: 'middle',
                                                        marginRight: '8px',
                                                    }}
                                                >
                                                    <Image src="/icons/4deabee68b28877f83689c02911e811e.svg" width="28" height="28" alt={'Line'}></Image>
                                                </span>
                                                LINE
                                            </Button>
                                        </Link>
                                    </div>
                                )}
                            </div>
                            <div className="items-protocols">
                                {t.rich('logintips.protocol', {
                                    link1: (children: any) => (
                                        <Link
                                            href={
                                                locale === 'cis'
                                                    ? 'https://account.ecoflow.com/agreement/ru-cis/PrivacyPolicy.html'
                                                    : locale === 'uk' && kaamelInfo?.privacyUrl
                                                    ? kaamelInfo?.privacyUrl
                                                    : `https://account.ecoflow.com/agreement/${i18nMap[locale] || i18nMap.us}/PrivacyPolicy.html`
                                            }
                                            newWin
                                            ariaLabel={t('privacy')}
                                        >
                                            {children}
                                        </Link>
                                    ),
                                    link2: (children: any) => (
                                        <Link
                                            href={
                                                locale === 'cis'
                                                    ? 'https://account.ecoflow.com/agreement/ru-cis/TermsOfUse.html'
                                                    : `https://account.ecoflow.com/agreement/${i18nMap[locale] || i18nMap.us}/TermsOfUse.html`
                                            }
                                            newWin
                                            ariaLabel={t('TOU')}
                                        >
                                            {children}
                                        </Link>
                                    ),
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageLayout>
    );
}

export async function getServerSideProps(context: any) {
    const { redirect } = context.query;
    const { locale } = context;
    if (['cn', 'br'].includes(locale)) {
        return redirectTo404();
    }
    let prevPath = context.req.headers.referer;

    if (prevPath) {
        prevPath = prevPath.indexOf('register') > 0 ? '' : prevPath;
    }
    return {
        props: {
            redirect: redirect || '',
            prevPath: prevPath || '',
        },
    };
}

// 校验上一页来源是否同源, 如果是返回目标route，否则返回空字符串
function validPrePath(path: string) {
    if (typeof window !== 'undefined') {
        const origin = (window as any).location.origin;
        return (path.includes(origin) && path) || '';
    }
    return '';
}
